import React, { useState } from "react";
import { Col, Row, Typography, Spin } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import axiosClient from "../../../utils/axios.tsx";
import { toast } from "react-toastify";

const Main = () => {
  const { Title, Text } = Typography;
  const navigate = useNavigate();
  const location = useLocation();
  const email = location.state?.email;

  const [isLoading, setIsLoading] = useState(false);
  const [canRequest, setCanRequest] = useState(true); // Lock to prevent multiple requests

  const resendHandler = () => {
    if (!canRequest) return; // Prevent multiple requests
    setIsLoading(true);
    setCanRequest(false); // Lock requests

    axiosClient()
      .get(`/users/request-reset-password/employee/${email}`)
      .then((res) => {
        setIsLoading(false);
        setCanRequest(true); // Unlock requests
        toast.success("Email sent!");
      })
      .catch((error) => {
        setIsLoading(false);
        setCanRequest(true); // Unlock requests on failure
        toast.error(error?.response?.data?.message);
      });
  };

  return (
    <Row>
      <Col span={24}>
        <div className="w-full flex justify-center pt-28">
          <div>
            <div className="justify-center grid">
              <img
                src="https://ik.imagekit.io/8extk8sjo/Featured%20icon.svg?updatedAt=1709120630375"
                alt=""
              />
            </div>

            <Title
              style={{
                fontSize: "30px",
              }}
              className="pt-4 text-[#101828] text-center font-semibold"
            >
              Check your email
            </Title>
            <div className="grid text-center">
              <Text className="text-[16px] -mt-2">
                We sent a password reset link <br /> to <strong>{email}</strong>
              </Text>
              <Text
                style={{
                  color: "#475467",
                }}
                className=" text-[14px] pt-6"
              >
                Didn’t receive the email? {""}
                <span
                  onClick={resendHandler}
                  className={`text-[#004EEB] cursor-pointer font-semibold text-[14px] ${
                    !canRequest ? "disabled-link" : ""
                  }`}
                >
                  {canRequest ? "Click to resend" : "Sending..."}
                </span>
                {isLoading && <Spin />}
              </Text>
              <div className="text-center mt-8 flex justify-center gap-2 items-center ">
                <img
                  src="https://ik.imagekit.io/8extk8sjo/Icon%20(16).svg?updatedAt=1709123059299"
                  alt=""
                />
                <Text
                  onClick={() => navigate("/signin")}
                  style={{
                    color: "#475467",
                  }}
                  className=" text-[14px] cursor-pointer font-semibold"
                >
                  Back to log in
                </Text>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default Main;
