import React, { useEffect, useState } from "react";
import { Row, Col, Button, Dropdown } from "antd";
import { Logo } from "../../components/index.ts";
import { NAV_ITEMS } from "../../constants/data.ts";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth-context.tsx";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
const Header = (): JSX.Element => {
  const navigate = useNavigate();
  const [number, setNumber] = useState(0);
  const { pathname } = useLocation();
  const handleNavigate = (item: any, index: number) => {
    navigate(item.url);
  };
  const payouts = useSelector((state: RootState) => state.payouts.payouts);
  const { user, signOut } = useAuthContext();

  const items = [
    {
      key: "1",
      label: (
        <div
          onClick={() => navigate("/settings")}
          className="w-full border-b border-[#EAECF0] font-sans gap-3 p-3 flex "
        >
          <div className="w-[55px] h-[44px]  rounded-[50%] overflow-hidden bg-gray-100 cursor-pointer">
            {user?.profile_image ? (
              <img
                src={user?.profile_image}
                alt=""
                className="object-cover w-full h-full"
              />
            ) : (
              <div className="flex items-center justify-center h-full w-full ">
                <p className="text-[28px] capitalize font-medium text-[#667085]">
                  {user?.name?.charAt(0)}
                </p>
              </div>
            )}
          </div>
          <div className="grid w-full ">
            <span className="text-[#344054] text-[14px] capitalize font-semibold">
              {user?.name}
            </span>
            <span className="-mt-1 w-full">{user?.email}</span>
          </div>
        </div>
      ),
    },

    {
      key: "4",
      label: (
        <div className="p-3 py-4">
          <div
            onClick={() => {
              navigate("/");
              signOut();
            }}
            className="flex items-center justify-start  w-full rounded-md  text-white font-sans gap-3"
          >
            <img
              src="https://ik.imagekit.io/8extk8sjo/log-out-01.svg?updatedAt=1710827754232"
              alt=""
            />
            <span className="text-[#344054] font-medium text-sm">Log out</span>
          </div>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setNumber(number + 1);
  }, []);

  return (
    <div className="w-full h-[72px] flex justify-center bg-white border-b border-[#EAECF0] font-sans employeHeader">
      <div className="container h-[72px] bg-[#FFFFFF] border-b border-[#EAECF0] fixed z-50 ">
        <Row>
          <Col span={3} className="flex items-center justify-start h-[72px] ">
            <Logo />
          </Col>
          <Col span={17} className="h-[72px] flex items-center gap-5 pl-6">
            {NAV_ITEMS?.map((item: any, index: number) => (
              <Button
                key={index}
                onClick={() => handleNavigate(item, index)}
                className={`h-[44px] shadow-none border-none  font-sans p-[8px, 12px, 8px, 12px] rounded-[6px] font-semibold text-[16px] text-[#344054] flex items-center gap-2  ${
                  pathname === item.url && "bg-[#EFF4FF]"
                }`}
              >
                {item?.label}
                {item?.isCount && (
                  <div className="rounded-[16px] bg-[#F2F4F7] px-3 font-sans">
                    {payouts?.length > 0 ? payouts?.length : 0}
                  </div>
                )}
              </Button>
            ))}
          </Col>
          <Col span={4} className="flex items-center justify-end gap-6">
            <div className="w-[44px] h-[44px] rounded-[50px] overflow-hidden bg-gray-100 cursor-pointer">
              <Dropdown
                menu={{ items }}
                placement="bottomRight"
                className="border-b border-[#EAECF0] shadow-none"
              >
                {user?.profile_image ? (
                  <img
                    src={user?.profile_image}
                    alt=""
                    className="object-cover w-full h-full"
                  />
                ) : (
                  <div className="flex items-center justify-center h-full w-full ">
                    <p className="text-[28px] capitalize font-medium text-[#667085]">
                      {user?.name?.charAt(0)}
                    </p>
                  </div>
                )}
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Header;
