// @ts-ignore
import React, { useEffect, useState } from "react";
import { Col, Row, DatePicker, Button, Input, Typography, Spin } from "antd";
import Card from "./card.tsx";
import TableSection from "./table-section.tsx";
import dayjs from "dayjs";
import Sidebar from "../../layout/sidebar/view.tsx";
import { useAuthContext } from "../../context/auth-context.tsx";
import { useSelector } from "react-redux";
import useOpenStats from "../../hooks/use-stats.tsx";
import useOpenPayouts from "../../hooks/use-payouts.tsx";
import { RootState } from "../../redux/store.ts";
import LineChart from "./lineChart.tsx";
import axiosClient from "../../utils/axios.tsx";
import Cookies from "js-cookie";

const Main = () => {
  const { Text } = Typography;
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [viewData, setViewData] = useState();
  const [userCompanies, setUserCompanies] = useState([]);
  const [graphData, setGraphData] = useState({});
  const [isGraphLoading, setisGraphLoading] = useState(false);
  const [year, setYear] = useState(dayjs(new Date()));

  interface Values {
    from: any;
    to: any;
  }
  const initialState: Values = { from: "", to: "" };

  const [values, setValues] = useState<Values | undefined>(initialState);
  const { user } = useAuthContext();
  const userId = Cookies.get("employee-user-id");
  const stats = useSelector((state: RootState) => state.stats.stats);
  const { getStats } = useOpenStats();
  const { getPayouts } = useOpenPayouts();

  const SearchIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/search-lg.svg?updatedAt=1709278920452"
        alt=""
      />
    );
  };

  useEffect(() => {
    getStats(values, user?.user_id && user);
    getPayouts(user?.user_id && user); //we don't need to pass date here.
  }, [values, user]);

  useEffect(() => {
    setUserCompanies(stats?.userCompanies);
  }, [stats]);

  const TOTAL_CARDS = [
    {
      label: "Unpaid",
      counts: ` $${
        user?.is_verified && stats?.userCommission
          ? stats?.userCommission?.unpaid?.toFixed(2)
          : "0"
      }`,
    },
    {
      label: "Paid",
      counts: ` $${stats?.userCommission?.paid?.toFixed(2) || "0"}`,
    },
    {
      label: "Total earnings",
      counts: `$${stats?.userCommission?.total?.toFixed(2) || "0"}`,
    },
  ];

  const TOTAL_CARDS2 = [
    {
      label: "Total Companies",
      counts: stats?.leadsCompanies || 0,
    },
    {
      label: "Total Companies Converted",
      counts: stats?.convertedCompanies || 0,
    },
    {
      label: "Conversion rate",
      counts: `${
        (user?.is_verified &&
          stats?.leadsCompanies &&
          `${(
            (stats?.convertedCompanies / stats?.leadsCompanies) *
            100
          ).toFixed(2)}%`) ||
        "0"
      }`,
    },
  ];

  useEffect(() => {
    if (userId) {
      setisGraphLoading(true);
      axiosClient()
        .get(
          `/pipedrive-organisations/user-graph?${
            userId ? "user_id=" + userId : ""
          }${year ? "&Year=" + year["$y"] : ""}`
        )
        .then((res) => {
          setGraphData(res?.data);
        })
        .catch((err) => {
          console.log("err", err);
        })
        .finally(() => setisGraphLoading(false));
    }
  }, [year]);

  const handleSearch = (value) => {
    let filteredData;
    if (value === "") {
      filteredData = stats?.userCompanies;
    } else {
      filteredData = stats?.userCompanies?.filter((x) => {
        return x?.Name?.toLowerCase().includes(value?.toLowerCase());
      });
    }
    setUserCompanies(filteredData);
  };

  return (
    <>
      {user?.name ? (
        <>
          <div className="container pt-28 ">
            <div className="grid">
              <Text className="text-[#101828] text-[30px] font-semibold capitalize">
                Welcome back, {user?.name}
              </Text>
              <Text className="text-[#475467] text-[16px]">
                Your current summary and activity.
              </Text>
            </div>

            <Row gutter={30}>
              {TOTAL_CARDS?.map((item: any) => (
                <Col span={8} className="mt-8">
                  <Card label={item?.label} count={item?.counts} />
                </Col>
              ))}
            </Row>

            <Row>
              <Col span={24} className="mt-8 overflow-hidden">
                <div className="lineGraph">
                  <Col className="generateTitle">
                    <div>
                      <h3>Your earnings</h3>
                      <h1>
                        {`$${
                          graphData?.yearlyRevenue
                            ? graphData?.yearlyRevenue?.toFixed(2)
                            : "0"
                        }`}
                      </h1>
                    </div>
                    <div className="w-[5.4rem]">
                      <DatePicker
                        className="dateYear"
                        picker="year"
                        minDate={dayjs(new Date(2022, 0, 1))}
                        maxDate={dayjs(new Date())}
                        value={year}
                        onChange={(e) => setYear(e)}
                        allowClear={false}
                        disabled={isGraphLoading}
                      />
                    </div>
                  </Col>
                  {!isGraphLoading ? (
                    <LineChart stats={graphData} />
                  ) : (
                    <div className="flex items-center justify-center py-[9.1rem]">
                      <Spin />
                    </div>
                  )}
                </div>
              </Col>
            </Row>

            <Row gutter={30}>
              {TOTAL_CARDS2?.map((item: any) => (
                <Col span={8} className="mt-8 ">
                  <Card label={item?.label} count={item?.counts} />
                </Col>
              ))}
            </Row>

            <Row>
              <Col span={24} className="my-8">
                <Input
                  style={{
                    boxShadow: "0px 1px 2px 0px #1018280D",
                  }}
                  onChange={(e) => handleSearch(e.target.value)}
                  placeholder="Search"
                  prefix={<SearchIcon />}
                  className="w-[400px] h-[44px] placeholder:text-black rounded-[8px]"
                />
              </Col>
              <Col span={24} className="mb-8">
                <TableSection
                  tableData={user?.is_verified ? userCompanies : []}
                  setIsViewOpen={setIsViewOpen}
                  setViewData={setViewData}
                />
              </Col>
              <Sidebar
                isViewOpen={isViewOpen}
                isCompany
                setIsViewOpen={setIsViewOpen}
                data={viewData}
                setViewData={setViewData}
              />
            </Row>
          </div>
        </>
      ) : (
        <Spin fullscreen />
      )}
    </>
  );
};

export default Main;
