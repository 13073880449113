import React, { useState } from "react";
import { Logo } from "../../../components/index.ts";
import { Typography, Button, Spin } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useAuthContext } from "../../../context/auth-context.tsx";

const LoginFormSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is required"),
  password: Yup.string().required("Password is required"),
});

const CustomIcon = () => {
  return (
    <img
      src="https://ik.imagekit.io/8extk8sjo/Social%20icon.svg?updatedAt=1709103355258"
      alt=""
      width={`25px`}
    />
  );
};

const LoginForm = () => {
  const { Text, Title } = Typography;
  const navigate = useNavigate();
  const [show,setShow] = useState(false);

  const { signIn } = useAuthContext();

  return (
    <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID">
      <div className="w-full flex items-center justify-center pt-12">
        <div className="w-[50%] h-full">
          <Logo />
          <Title className="pt-20 font-sans " style={{ fontWeight: "600" }}>
            Welcome Back
          </Title>
          <Formik
            initialValues={{
              email: "",
              password: "",
            }}
            validationSchema={LoginFormSchema}
            onSubmit={async (values) => {
              await signIn(values.email.toLocaleLowerCase(), values.password);
            }}
          >
            {({ errors, touched, isSubmitting }) => (
              <Form>
                <div className="pt-4">
                  <div>
                    <Title
                      level={5}
                      style={{
                        color: "#344054",
                      }}
                      className="font-sans font-nomral text-[14px]"
                    >
                      Email*
                    </Title>
                    <Field
                      placeholder="Enter your email"
                      label="Email*"
                      name="email"
                      className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal lowercase"
                    />
                    <div className="h-[16px]">
                      <ErrorMessage
                        name="email"
                        render={(msg) => (
                          <div className="text-red-500  text-[12px] h-[5px] ">
                            {msg}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="">
                    <Title
                      level={5}
                      style={{
                        color: "#344054",
                      }}
                      className="font-sans font-nomral text-[14px] "
                    >
                      Password*
                    </Title>
                    <div className="relative">
                    <Field
                      type={show ? "text" : "password"}
                      name="password"
                      placeholder="Enter your password"
                      className="w-full h-[44px] bg-white rounded-[8px] border-[1.5px] border-[#D0D5DD] font-semibold text-[16px] text-[#344054] px-2 placeholder:font-normal"                     
                    />
                    <div onClick={() => setShow(!show)} className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer text-gray-400">
                      {show ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
                    </div>
                    </div>
                    <div className="h-[10px]">
                      <ErrorMessage
                        name="password"
                        render={(msg) => (
                          <div className="text-red-500 text-[12px] h-[5px]">
                            {msg}
                          </div>
                        )}
                      />
                    </div>
                  </div>
                  <div className="text-left  ">
                    <Text
                      style={{
                        color: "#475467",
                      }}
                      className=" text-[14px] font-sans"
                    >
                      Forgot password?{" "}
                      <span
                        onClick={() => navigate("/forget-password")}
                        className="text-[#004EEB] cursor-pointer font-semibold text-[14px] font-sans"
                      >
                        Reset password
                      </span>
                    </Text>
                  </div>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="w-full font-sans h-[44px] bg-[#155EEF] rounded-[8px] font-semibold text-[16px] text-[#fff] mt-4"
                    disabled={isSubmitting} // Disable button while submitting
                  >
                    {isSubmitting ? <Spin /> : "Login"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </GoogleOAuthProvider>
  );
};

export default LoginForm;
