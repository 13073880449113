import React, { useEffect, useState } from "react";
import { Heading } from "../../components/index.ts";
import Card from "./card.tsx";
import Sidebar from "../../layout/sidebar/view.tsx";
import { DASHBOARD_BTNS } from "../../constants/data.ts";
import {
  Row,
  Col,
  Button,
  DatePicker,
  Input,
  TimeRangePickerProps,
  Typography,
  Spin,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import TableSection from "./table-section.tsx";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store.ts";
import { useAuthContext } from "../../context/auth-context.tsx";
import useOpenStats from "../../hooks/use-stats.tsx";
import useOpenPayouts from "../../hooks/use-payouts.tsx";
import moment from "moment";

const Main = (): JSX.Element => {
  const { Text } = Typography;
  const { RangePicker } = DatePicker;
  interface Values {
    from: string;
    to: string;
  }

  const initialState: Values = { from: "", to: "" };
  const [isLoading, setIsLoading] = useState(true);
  const [values, setValues] = useState<Values | undefined>(initialState);
  const [tempValues, setTempValues] = useState<Values | undefined>(
    initialState
  );
  const [isViewOpen, setIsViewOpen] = useState<any>(false);
  const payouts = useSelector((state: RootState) => state.payouts.payouts);
  const [payoutData, setPayoutData] = useState(payouts);
  const [activeTab, setActiveTab] = useState(0);
  const [dateShow, setDateShow] = useState(false);
  const [viewData, setViewData] = useState();
  const { getStats } = useOpenStats();
  const { getPayouts } = useOpenPayouts();
  const { user } = useAuthContext();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Number of items per page
  const stats = useSelector((state: RootState) => state.stats.stats);

  useEffect(() => setPayoutData(payouts), [payouts]);

  useEffect(() => {
    setIsLoading(true);
    getStats(values, user?.user_id && user).then(() => setIsLoading(false));
    getPayouts(user?.user_id && user, values).then(() => setIsLoading(false));
  }, [user, values]);

  const TOTAL_CARDS = [
    {
      label: "Unpaid",
      counts: ` $${
        stats?.userCommission ? stats?.userCommission?.unpaid?.toFixed(2) : "0"
      }`,
    },
    {
      label: "Paid",
      counts: ` $${stats?.userCommission?.paid?.toFixed(2) || "0"}`,
    },
    {
      label: "Total earnings",
      counts: `$${stats?.userCommission?.total?.toFixed(2) || "0"}`,
    },
  ];

  const Customimage = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/Icon%20(18).svg?updatedAt=1709195817408"
        alt=""
      />
    );
  };

  const SearchIcon = () => {
    return (
      <img
        src="https://ik.imagekit.io/8extk8sjo/search-lg.svg?updatedAt=1709278920452"
        alt=""
      />
    );
  };

  const handleSearch = (value) => {
    let filteredData;
    if (value === "") {
      filteredData = payouts;
    } else {
      filteredData = payouts?.filter((x) => {
        return x?.pipedrive_org_id?.Name?.toLowerCase().includes(
          value?.toLowerCase()
        );
      });
    }
    setPayoutData(filteredData);
  };

  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setTempValues({
        from: dateStrings[0],
        to: dateStrings[1],
      });
    } else {
      setTempValues(initialState);
    }
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: "Today", value: [dayjs().startOf("day"), dayjs().endOf("day")] },
    {
      label: "Yesterday",
      value: [
        dayjs().subtract(1, "day").startOf("day"),
        dayjs().subtract(1, "day").endOf("day"),
      ],
    },
    {
      label: "This Week",
      value: [dayjs().startOf("week"), dayjs().endOf("week")],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
    {
      label: "This Year",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
    {
      label: "Last Year",
      value: [
        dayjs().subtract(1, "year").startOf("year"),
        dayjs().subtract(1, "year").endOf("year"),
      ],
    },
    { label: "All Time", value: [dayjs("2021-12-31"), dayjs()] },
  ];

  const RangePickerFooter = ({
    onOk,
    onCancel,
    selectedDates,
  }: {
    onOk: () => void;
    onCancel: () => void;
    selectedDates: [string, string];
  }) => (
    <div className="w-full py-4">
      <div className="flex justify-between mb-4">
        <Input
          value={
            selectedDates[0]
              ? moment(selectedDates[0]).format("DD/MM/YYYY")
              : ""
          }
          placeholder="Start Date"
          readOnly
          className="w-[45%]"
        />
        <Input
          value={
            selectedDates[1]
              ? moment(selectedDates[1]).format("DD/MM/YYYY")
              : ""
          }
          placeholder="End Date"
          readOnly
          className="w-[45%]"
        />
      </div>
      <div className="flex justify-end gap-3 items-center">
        <Button
          style={{
            boxShadow: "0px 1px 2px 0px #1018280D",
          }}
          className="rounded-[8px] h-[40px]"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          style={{
            boxShadow: "0px 1px 2px 0px #1018280D",
          }}
          className="bg-[#155EEF] rounded-[8px] h-[40px]"
          type="primary"
          onClick={onOk}
        >
          Apply
        </Button>
      </div>
    </div>
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const totalItems = payoutData?.length;
  const totalPages = Math.ceil(totalItems / pageSize);

  const paginatedData = payoutData?.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    let newValues: Values;
  
    switch (index) {
      case 0: // All time (starting from 2022)
        newValues = {
          from: "2021-12-31", // This sets it to the last day of 2021, effectively starting from 2022
          to: dayjs().format("YYYY-MM-DD"),
        };
        break;
      case 1: // 30 days
        newValues = {
          from: dayjs().subtract(30, "day").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
        break;
      case 2: // 7 days
        newValues = {
          from: dayjs().subtract(7, "day").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
        break;
      case 3: // 24 hours
        newValues = {
          from: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
          to: dayjs().format("YYYY-MM-DD"),
        };
        break;
      default:
        newValues = initialState;
    }
  
    setValues(newValues);
    setTempValues(newValues);
  };

  return (
    <>
      <div className="container pt-28 text-[30px]">
        <Heading heading="Commissions" subHeading="" />
        {isLoading ? (
          <Spin fullscreen />
        ) : (
          <>
            <Row className="mt-8">
              <Col span={12}>
                <div className="flex items-center">
                  {DASHBOARD_BTNS.map((item, index) => (
                    <Button
                      key={index}
                      onClick={() => handleTabClick(index)}
                      style={{
                        boxShadow: item?.style?.shadow,
                        borderTop: item?.style?.borderTop,
                        borderBottom: item?.style?.borderBottom,
                        border: item?.style?.border,
                      }}
                      className={`${item?.className} ${
                        activeTab === index ? "bg-transparent" : "bg-white"
                      }`}
                    >
                      {item?.label}
                    </Button>
                  ))}
                </div>
              </Col>
              <Col span={12} className="flex justify-end">
                {dateShow && (
                  <RangePicker
                    open={dateShow}
                    presets={rangePresets}
                    onChange={onRangeChange}
                    suffixIcon={<Customimage />}
                    className="border border-[#D0D5DD] h-[40px]"
                    renderExtraFooter={() => (
                      <RangePickerFooter
                        selectedDates={[tempValues.from, tempValues.to]}
                        onOk={() => {
                          setValues(tempValues);
                          setDateShow(false);
                        }}
                        onCancel={() => {
                          setTempValues(initialState);
                          setValues(initialState);
                          setDateShow(false);
                        }}
                      />
                    )}
                  />
                )}
                {!dateShow && (
                  <div
                    onClick={() => setDateShow(true)}
                    style={{
                      boxShadow: "0px 1px 2px 0px #1018280D",
                      padding: "5px 16px",
                    }}
                    className={`border cursor-pointer z-40 border-[#D0D5DD] rounded-[8px] bg-white flex items-center gap-3 justify-between ${
                      values?.from && values?.to ? "w-[290px]" : "w-[140px]"
                    }`}
                  >
                    <div className="flex items-center gap-3">
                      <Customimage />
                      <Text className="text-[#344054] font-semibold text-sm">
                        {values?.from && values?.to
                          ? `${moment(values.from).format(
                              "DD/MM/YYYY"
                            )} - ${moment(values.to).format("DD/MM/YYYY")}`
                          : "Select dates"}
                      </Text>
                    </div>
                    {values?.from && values?.to && (
                      <Button
                        type="text"
                        icon={<CloseOutlined />}
                        onClick={(e) => {
                          e.stopPropagation();
                          setValues(initialState);
                          setTempValues(initialState);
                        }}
                      />
                    )}
                  </div>
                )}
              </Col>
            </Row>
            <Row gutter={30}>
              {TOTAL_CARDS.map((item: any, index) => (
                <Col key={index} span={8} className="mt-8">
                  <Card label={item?.label} count={item?.counts} />
                </Col>
              ))}
            </Row>
            <Input
              style={{
                boxShadow: "0px 1px 2px 0px #1018280D",
              }}
              onChange={(e) => handleSearch(e.target.value)}
              placeholder="Search"
              prefix={<SearchIcon />}
              className="w-[400px] h-[44px] placeholder:text-black rounded-[8px] mt-10"
            />

            <div
              style={{
                boxShadow:
                  "0px 1px 2px 0px #1018280F,0px 1px 3px 0px #1018281A",
              }}
              className="rounded-[12px] border border-[#EAECF0] bg-white overflow-hidden mt-8"
            >
              <TableSection
                setIsViewOpen={setIsViewOpen}
                isShow={user?.is_verified}
                payouts={
                  paginatedData?.map((item) => ({
                    date: moment(item.created_at).format("DD/MM/YYYY"),
                    company: item.pipedrive_org_id.Name,
                    event: `${item?.revenue_event}`,
                    amount: `$${item?.total_revenue}`,
                    percent: `${item?.commission}%`,
                    c_amount: `$${item?.commission_paid}`,
                    View:
                      item?.payment_status === "Paid" ? (
                        <Text
                          className="text-[14px] font-semibold capitalize w-auto rounded-[16px] p-1 px-3"
                          style={{
                            backgroundColor: "#ECFDF3",
                            color: "#027A48",
                          }}
                        >
                          {item?.payment_status}
                        </Text>
                      ) : item?.payment_status === "Unpaid" ? (
                        <Text
                          className="text-[14px] font-semibold capitalize w-auto rounded-[16px] p-1 px-3"
                          style={{
                            backgroundColor: "#FFFAEB",
                            color: "#B54708",
                          }}
                        >
                          {item?.payment_status}
                        </Text>
                      ) : (
                        "No Status"
                      ),
                  })) || []
                }
              />
              <div className="w-full bg-white h-20 items-center px-4 flex justify-between gap-4">
                <Text className="text-[#344054] text-[14px] font-medium ">
                  Page {currentPage} of {totalPages}
                </Text>

                <div className="flex items-center gap-3">
                  <button
                    style={{
                      boxShadow: "0px 1px 2px 0px #1018280D",
                    }}
                    className="rounded-[8px] py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Previous
                  </button>
                  <button
                    style={{
                      boxShadow: "0px 1px 2px 0px #1018280D",
                    }}
                    className="rounded-[8px] py-[8px] px-[14px] text-[#344054] border border-[#D0D5DD] hover:border-blue-500 text-sm hover:text-blue-500 transition-all"
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div>
              <Sidebar
                setIsViewOpen={setIsViewOpen}
                isCompany={false}
                isViewOpen={isViewOpen}
                data={viewData}
                setViewData={setViewData}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Main;
